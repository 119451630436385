var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"ma-8",attrs:{"shaped":""}},[_c('v-card-title',{staticClass:"indigo darken-4 white--text",attrs:{"dark":""}},[_vm._v(" Relatório do pedido: "+_vm._s(_vm.relatorio.numeroPedido)+" ")]),_c('v-card-text',[_c('v-card',{staticClass:"ma-2",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.relatorio.tipoPedido)+" "),_c('v-spacer'),(_vm.pedido.estado === 'Validado')?_c('v-chip',{attrs:{"outlined":"","color":"green"}},[_vm._v(" "+_vm._s(_vm.relatorio.estadoPedido)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("assignment_turned_in")])],1):(_vm.pedido.estado === 'Devolvido')?_c('v-chip',{attrs:{"outlined":"","color":"red"}},[_vm._v(" "+_vm._s(_vm.pedido.estado)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("assignment_late")])],1):_vm._e()],1),_c('v-card-subtitle',[_c('b',[_vm._v("Data de criação:")]),_vm._v(" "),_c('u',[_vm._v(_vm._s(_vm.relatorio.dataInicial))]),_c('br'),_c('b',[_vm._v("Data de finalização:")]),_vm._v(" "),_c('u',[_vm._v(_vm._s(_vm.relatorio.dataFinal))])]),_c('v-card-text',[(_vm.pedido.objeto.acao !== 'Criação')?_c('span',[_c('v-alert',{staticClass:"m-auto mb-2 mt-2",attrs:{"type":"info","width":"90%","outlined":""}},[_c('span',[_vm._v(" "+_vm._s(_vm.relatorio.alteracaoInfo)+" ")])]),_c('v-divider',{staticClass:"m-auto mb-2"})],1):_vm._e(),_c('v-card',{staticClass:"ma-1"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeader,"items":_vm.relatorio.comparacao,"hide-default-footer":"","calculate-widths":"","footer-props":_vm.footerProps},scopedSlots:_vm._u([{key:"item.campo",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.campo))])]}},{key:"item.nota",fn:function(ref){
var item = ref.item;
return [(!item.finalizado.nota)?_c('v-icon',{attrs:{"disabled":""}},[_vm._v(" speaker_notes_off ")]):_c('v-badge',{attrs:{"color":"blue","dot":""}},[_c('v-icon',{on:{"click":function($event){return _vm.abrirNotaDialog(item.finalizado.nota)}}},[_vm._v(" speaker_notes ")])],1)]}},{key:"item.submetido",fn:function(ref){
var item = ref.item;
return [(
                    item.submetido === undefined ||
                      item.submetido.dados === '' ||
                      item.submetido.dados === null
                  )?_c('v-alert',{staticClass:"pa-2 ma-2 ml-0 mr-0",attrs:{"border":"right","text":"","color":"grey","icon":"not_interested"}},[_vm._v(" Campo não preenchido ")]):(item.submetido.dados instanceof Array)?_c('v-alert',{staticClass:"pa-2 ma-2 ml-0 mr-0",attrs:{"border":"right","text":"","color":_vm.cores(item.submetido.cor),"icon":_vm.icons(item.submetido.cor)}},[(item.submetido.dados.length === 0)?_c('v-chip',{attrs:{"color":"grey","label":"","outlined":""}},[_vm._v(" A lista está vazia. ")]):_c('ul',{staticClass:"ma-0"},_vm._l((item.submetido.dados),function(dado,i){return _c('span',{key:i},[(dado.sigla)?_c('li',[_vm._v(" "+_vm._s(dado.sigla)+" ")]):_c('li',[_vm._v(" "+_vm._s(dado.codigo)+" ")])])}),0)],1):_c('v-alert',{staticClass:"pa-2 ma-2 ml-0 mr-0",attrs:{"border":"right","text":"","color":_vm.cores(item.submetido.cor),"icon":_vm.icons(item.submetido.cor)}},[_vm._v(" "+_vm._s(item.submetido.dados)+" ")])]}},{key:"item.finalizado",fn:function(ref){
                  var item = ref.item;
return [(
                    item.finalizado === undefined ||
                      item.finalizado.dados === '' ||
                      item.finalizado.dados === null
                  )?_c('v-alert',{staticClass:"pa-2 ma-2 ml-0 mr-0",attrs:{"border":"right","text":"","color":"grey","icon":"not_interested"}},[_vm._v(" Campo não preenchido ")]):(item.finalizado.dados instanceof Array)?_c('v-alert',{staticClass:"pa-2 ma-2 ml-0 mr-0",attrs:{"border":"right","text":"","color":_vm.cores(item.finalizado.cor),"icon":_vm.icons(item.finalizado.cor)}},[(item.finalizado.dados.length === 0)?_c('v-chip',{attrs:{"color":"grey","label":"","outlined":""}},[_vm._v(" A lista está vazia. ")]):_c('ul',{staticClass:"ma-0"},_vm._l((item.finalizado.dados),function(dado){return _c('span',{key:dado.sigla},[(dado.sigla)?_c('li',[(
                            _vm.novoItemAdicionado(
                              dado.sigla,
                              item.submetido,
                              'sigla'
                            )
                          )?_c('v-badge',{attrs:{"right":"","dot":"","inline":""}},[_vm._v(_vm._s(dado.sigla))]):_c('span',[_vm._v(" "+_vm._s(dado.sigla)+" ")])],1):_c('li',[(
                            _vm.novoItemAdicionado(
                              dado.codigo,
                              item.submetido,
                              'codigo'
                            )
                          )?_c('v-badge',{attrs:{"right":"","dot":"","inline":""}},[_vm._v(_vm._s(dado.codigo))]):_c('span',[_vm._v(" "+_vm._s(dado.codigo)+" ")])],1)])}),0)],1):_c('v-alert',{staticClass:"pa-2 ma-2 ml-0 mr-0",attrs:{"border":"right","text":"","color":_vm.cores(item.finalizado.cor),"icon":_vm.icons(item.finalizado.cor)}},[_vm._v(" "+_vm._s(item.finalizado.dados)+" ")])]}}],null,true)})],1),(_vm.relatorio.despacho.trim() !== '')?_c('div',{staticClass:"mt-2"},[_c('v-divider',{staticClass:"m-auto mb-4"}),_c('v-textarea',{attrs:{"value":_vm.relatorio.despacho,"label":"Despacho","shaped":"","rounded":"","filled":"","readonly":"","hide-details":"","no-resize":""}})],1):_vm._e()],1)],1)],1),_c('v-card-actions',{staticClass:"ml-4"},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"indigo","dark":""},on:{"click":_vm.voltar}},[_vm._v("Voltar")]),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2 mr-4",attrs:{"color":"indigo accent-4","dark":""},on:{"click":_vm.geraPDF}},[_vm._v(" Descarregar "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("picture_as_pdf")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"70%","persistent":""},model:{value:(_vm.notaDialog.visivel),callback:function ($$v) {_vm.$set(_vm.notaDialog, "visivel", $$v)},expression:"notaDialog.visivel"}},[_c('ConsultarNota',{attrs:{"nota":_vm.notaDialog.nota},on:{"fecharDialog":_vm.fecharNotaDialog}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }